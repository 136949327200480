import React from 'react'

import data from '../planets.json'

class Planets extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            planets: data.planets,
        }
    }

    componentDidMount() {
        // Make sure to run this only once.
        if (this.moveInitialized) {
            return
        }
        this.moveInitialized = true
        // Function to determine the speed according to the lifetime
        const accelerationValue = (time, lifetime) => -Math.pow((time * 2 / lifetime - 1), 4) + 1
        const randomVelocity = () => Math.random() * 2 * data.speed - data.speed
        const start = Date.now()
        let previousFrameTime = performance.now()
        let plans = this.state.planets.slice()
        plans.forEach((planet) => {
            // Speed on each axis. No flooring for better randomness in the directions.
            planet.moveX = randomVelocity()
            planet.moveY = randomVelocity()
            // Time interval at which the direction is reversed.
            planet.lifetime = Math.floor(Math.random() * 5000) + 5000
            // Prevent excessive moving around. Planets end up overlapping, wich is kinda ugly
            setInterval(() => {
                planet.moveX *= -1
                planet.moveY *= -1
            }, planet.lifetime)
        })
        this.setState({ planets: plans })
        const loop = (frameTime) => {
            window.requestAnimationFrame(loop)
            const delta = frameTime - previousFrameTime
            previousFrameTime = frameTime
            let plans = this.state.planets.slice()
            plans.forEach((planet) => {
                if (!planet.moveX) {
                    return
                }

                if (planet.cx + planet.r >= 486 || planet.cx - planet.r <= 0) {
                    planet.moveX = -planet.moveX
                }
                if (planet.cy + planet.r >= 420 || planet.cy - planet.r <= 0) {
                    planet.moveY = -planet.moveY
                }
                // When we are close to reversing the direction (see above), slow down the planet.
                const now = Date.now()
                const elapsed = now - start
                const acc = accelerationValue(elapsed % planet.lifetime, planet.lifetime)
                planet.cx += planet.moveX * acc * (delta / 32)
                planet.cy += planet.moveY * acc * (delta / 32)
            })
            this.setState({ planets: plans })
        }
        window.requestAnimationFrame(loop)
        // resume after re-focus
        let visibilityChange, hidden
        if (typeof document.hidden !== `undefined`) {
            hidden = `hidden`
            visibilityChange = `visibilitychange`
        } else if (typeof document.msHidden !== `undefined`) {
            hidden = `msHidden`
            visibilityChange = `msvisibilitychange`
        } else if (typeof document.webkitHidden !== `undefined`) {
            hidden = `webkitHidden`
            visibilityChange = `webkitvisibilitychange`
        }
        document.addEventListener(visibilityChange, () => {
            if (!document[hidden]) {
                previousFrameTime = performance.now()
            }
        })
    }

    render() {
        let bonds = {}
        data.bonds.forEach((e) => {
            let { from, to } = e
            let sPlanet = this.state.planets.filter(e => e.id == from)[0]
            let tPlanet = this.state.planets.filter(e => e.id == to)[0]
            bonds[e.id] = bonds[e.id] || {}
            bonds[e.id].path = `M${sPlanet.cx},${sPlanet.cy}L${tPlanet.cx},${tPlanet.cy}`
            bonds[e.id].opacity = 5e4 / (Math.pow(sPlanet.cx - tPlanet.cx, 2) + Math.pow(sPlanet.cy - tPlanet.cy, 2))
        })

        return <>
            <div>
                <svg id="planets" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486 420">
                    <defs>
                        <filter
                            id="a"
                            width="5"
                            height="5"
                            x="-1"
                            y="-1"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodColor="#000"
                                floodOpacity="0.7"
                                result="flood"
                            ></feFlood>
                            <feComposite
                                in="SourceGraphic"
                                in2="flood"
                                operator="in"
                                result="composite1"
                            ></feComposite>
                            <feGaussianBlur
                                in="composite1"
                                result="blur"
                                stdDeviation="3"
                            ></feGaussianBlur>
                            <feOffset result="offset"></feOffset>
                            <feComposite
                                in="SourceGraphic"
                                in2="offset"
                                result="composite2"
                            ></feComposite>
                        </filter>
                    </defs>
                    <g fill="none" stroke="#555" strokeWidth="2.5" strokeLinecap="round">
                        {data.bonds.map((bond, i) => <path
                            key={i}

                            d={bonds[bond.id].path}
                            opacity={bonds[bond.id].opacity}
                        >
                        </path>)}
                    </g>
                    <g strokeWidth="0.5" filter="url(#a)">
                        {this.state.planets.map((planet, i) => <circle
                            key={i}

                            id={planet.id}
                            cx={planet.cx}
                            cy={planet.cy}
                            fill={planet.fill}
                            stroke={planet.fill}
                            r={planet.r}>
                        </circle>)}
                    </g>
                </svg>
            </div>
        </>
    }
}

export default Planets
