import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withPrefix, Link, StaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'

import { Navigation } from '.'
import Particles from './Particles'
// import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css'
import Planets from './Planets'

import header from '../header.json'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    // TODO: Twitter account
    /*const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null*/

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
                <script src={withPrefix(`particles.js`)} type="text/javascript" />
            </Helmet>

            <div className="viewport">
                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header className="site-head">
                        <section className="top-container" id="header">
                            <Particles />
                            <div className="navigation-bar">
                                <div className="nav-left">
                                    <div>
                                        <Link to="/">
                                            <NavLogo />
                                        </Link>
                                        <Navigation data={site.navigation} navClass="site-nav-item" />
                                    </div>
                                </div>
                            </div>
                            {isHome ? <div className="header-content">
                                <div className="planets-container">
                                    <Planets />
                                </div>
                                <div className="presentation-container">
                                    <div className="presentation-left-container">
                                        <h1 className="pres-text">
                                            {header.catchphrase.map((part, index) => <span
                                                key={index}
                                                className={index % 2 ? `blue` : ``}
                                            >
                                                {part}
                                            </span>)}
                                        </h1>
                                        <p>
                                            {header.content}
                                        </p>
                                    </div>
                                </div>
                            </div> : null}
                            <div className="sep-container">
                                <div className="inner">
                                </div>
                            </div>
                        </section>
                    </header>

                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">{site.title}</Link> © 2019 &mdash; Tous droits reservés
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

function NavLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            className="navigation-logo"
            version="1.1"
            viewBox="0 0 300 500"
            xmlSpace="preserve"
        >
            <g fillRule="evenodd" clipRule="evenodd">
                <path d="M67.777 371.023a450990.25 450990.25 0 0033.521-42.16c-18.791 0-31.167.422-43.507-.087-27.218-1.113-41.142-14.024-41.422-40.845-.638-60.656-.501-121.319-.043-181.976.188-25.385 14.507-38.872 39.495-40.178 16.498-.856 33.162-1.295 49.602-.027 35.151 2.715 67.736 1.188 86.855-35.487 4.77-9.146 13.995-11.166 24.462-7.638.659 10.487-8.254 15.414-13.615 21.896-39.816 48.162-80.304 95.767-120.066 143.972-24.992 30.303-17.834 56.386 18.409 70.414 35.852 13.877 44.107 43.628 20.617 74.311-29.147 38.071-29.147 38.071-54.308 37.805z"></path>
                <path d="M232.024 140.382c-10.152 13.615-19.173 25.713-31.842 42.712 17.539 0 29.955-.465 42.32.095 27.104 1.243 40.832 14.243 41.107 41.119.615 59.718.572 119.458.07 179.18-.24 28.782-13.59 42.058-42.225 42.856-24.785.696-49.72 1.585-74.394-.254-21.606-1.606-36.553 5.354-48.726 23.07-7.875 11.461-15.534 26.663-38.121 18.698 18.064-21.723 35.353-42.582 52.723-63.382 28.854-34.554 58.22-68.688 86.482-103.716 22.082-27.367 13.93-54.567-18.913-67.296-39.439-15.286-47.789-44.676-19.467-76.981 13.076-14.912 19.762-41.357 50.986-36.101z"></path>
            </g>
        </svg>
    )
}

export default DefaultLayoutSettingsQuery
