import React from 'react'
import particleSettings from '../particle-settings.json'

class Particles extends React.Component {
    componentDidMount() {
        // eslint-disable-next-line no-undef
        if (window.particlesJS) {
            // eslint-disable-next-line no-undef
            particlesJS(`top-container-particles`, particleSettings)
        } else {
            setTimeout(this.componentDidMount, 200)
        }
    }

    render() {
        return <>
            <div id="top-container-particles">
            </div>
        </>
    }
}

export default Particles
